<template>
  <b-table
    ref="btable"
    :loading="loading"
    detailed
    custom-detail-row
    :data="tSoftCheckinProjectionStructs"
    default-sort="lessonDateSortVal"
    default-sort-direction="desc"
    @select="(row) => $refs.btable.toggleDetails(row)"
    @details-open="(row) => didToggleDetail(row, true)"
    @details-close="(row) => didToggleDetail(row, false)"
    :row-class="(row, index) => 'projection_tr has-background-white-bis'"
    style="font-size: 0.9rem"
  >
    <b-table-column
      label="実施日"
      width="14em"
      sortable
      field="lessonDateSortVal"
      v-slot="props"
    >
      <span>{{ props.row.lessonDate.format('YYYY/MM/DD(dd)') }}</span>
      <HolidayIcon
        :area="props.row.area"
        :date="props.row.lessonDate"
        blankIfNot
      />
    </b-table-column>
    <b-table-column label="施設" width="7em" v-slot="props">
      <span v-if="props.row.facility">{{
        props.row.facility.options.shortLabel
      }}</span>
      <span v-else />
    </b-table-column>
    <b-table-column label="講師" width="8em">
      <span />
    </b-table-column>
    <b-table-column label="プログラム">
      <span />
    </b-table-column>
    <b-table-column label="本数/時間" width="6em" numeric v-slot="props">
      <span>{{ props.row.tSoftCheckins.length }}本</span>
    </b-table-column>
    <b-table-column width="2em" centered>
      <span />
    </b-table-column>
    <b-table-column label="カテゴリ" width="6em">
      <span />
    </b-table-column>
    <b-table-column label="単価" width="5em" numeric v-slot="{ row }">
      <small v-if="!row.allHasUnitAmount" class="has-text-danger">未設定</small>
      <span v-else />
    </b-table-column>
    <b-table-column label="各種手当" width="6em" numeric>
      <span />
    </b-table-column>
    <b-table-column label="固定" width="5em" numeric>
      <span />
    </b-table-column>
    <b-table-column label="承認" width="6em" centered v-slot="{ row }">
      <b-icon icon="check" type="is-primary" v-if="row.allConfirmFlag" />
      <b-button
        v-else-if="row.detailOpened"
        size="is-small"
        type="is-danger"
        @click.prevent.stop="willCheckStructAll(row)"
        icon-left="check"
        outlined
        >一括</b-button
      >
      <b-icon v-else icon="close" type="is-danger"></b-icon>
    </b-table-column>
    <b-table-column label="" width="4em">
      <span />
    </b-table-column>

    <template #detail="props">
      <SoftCheckinTableDetailRow
        v-for="row in props.row.tSoftCheckins"
        :key="`detail_${row.id}`"
        :row="row"
        @didEdit="
          (tSoftCheckin) => didEditTSoftCheckin(props.row, tSoftCheckin)
        "
        @didDelete="(checkInId) => didDeleteTSoftCheckin(props.row, checkInId)"
        @change-confirm="
          (bool) =>
            bool ? props.row.syncAllConfirmFlag() : props.row.breakConfirmFlag()
        "
      />
    </template>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="loading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import { ApiCheckin } from '@/api'
import { TSoftCheckin, TSoftCheckinProjectionStruct } from '@/types/typePayment' // eslint-disable-line no-unused-vars
import SoftCheckinTableDetailRow from './SoftCheckinTableDetailRow.vue'
import HolidayIcon from '../Shared/HolidayIcon.vue'

export default {
  components: { SoftCheckinTableDetailRow, HolidayIcon },
  props: {
    loading: Boolean,
    total: Number,
    tSoftCheckinProjectionStructs: { // TSoftCheckinProjectionStruct
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * @param {TSoftCheckinProjectionStruct} projectionStruct
     * @param {boolean} opend
     * */
    didToggleDetail (projectionStruct, opend) {
      projectionStruct.detailOpened = opend
    },
    /** @param {TSoftCheckinProjectionStruct} projectionStruct */
    willCheckStructAll (projectionStruct) {
      console.log(projectionStruct, 'willCheckStructAll')
      this.$buefy.dialog.confirm({
        message: '全て承認済みにしますか？',
        onConfirm: () => {
          ApiCheckin.toggleConfirm(projectionStruct.checkinIds, true).then(() => {
            projectionStruct.setConfirmedAll(true)
          })
        }
      })
    },
    /**
     * @param {TSoftCheckinProjectionStruct} projectionStruct
     * @param {TSoftCheckin} tSoftCheckin
     * */
    didEditTSoftCheckin (projectionStruct, tSoftCheckin) {
      console.log([projectionStruct, tSoftCheckin], 'didEditTSoftCheckin')
      projectionStruct.replaceTSoftCheckin(tSoftCheckin)
    },
    /**
     * @param {TSoftCheckinProjectionStruct} projectionStruct
     * @param {number} checkinId
     * */
    didDeleteTSoftCheckin (projectionStruct, checkinId) {
      console.log([projectionStruct, checkinId], 'didDeleteTSoftCheckin')
      projectionStruct.removeTSoftCheckin(checkinId)
      if (!projectionStruct.hasCheckins) {
        this.$emit('will-remove', projectionStruct)
      }
    },
  }
}
</script>

<style>
.table tr.projection_tr {
  cursor: pointer;
}
.table tr.projection_tr:not(:first-child) td:not(:first-child) {
  border-top: 1px solid #dbdbdb;
}
</style>