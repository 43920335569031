<template>
  <section class="section is-main-section">
    <b-message type="is-success" v-if="hasCalcPermission && tPaymentWork">
      <div class="level">
        <div class="level-left">
          <p class="level-item" v-if="tPaymentWork.fixStatus.is('Init')">
            {{ tPaymentWork.versionAt.format('YYYY/MM/DD HH:mm:ss') }}<br />
            計算結果が作成されています。確定・csv出力・その他給設定を行うことができます。
          </p>
          <p class="level-item" v-else>
            {{ tPaymentWork.versionAt.format('YYYY/MM/DD HH:mm:ss') }}<br />
            計算結果が作成・確定されています。
          </p>
        </div>
        <div class="level-right">
          <p class="level-item">
            <b-button
              type="is-primary"
              icon-right="repeat"
              outlined
              @click="willCalcurate"
              :loading="isLoading"
              v-if="tPaymentWork.fixStatus.is('Init')"
              >再計算する</b-button
            >
          </p>
          <p class="level-item">
            <b-button
              type="is-info"
              icon-right="chevron-right"
              @click="willToWork"
              :loading="isLoading"
              >計算結果を確認する</b-button
            >
          </p>
        </div>
      </div>
    </b-message>
    <b-message type="is-dark" v-else-if="hasCalcPermission">
      <div class="level">
        <div class="level-left">
          <p class="level-item">
            計算結果は作成されていません。
            <br />承認済みの実績から、支払い額を計算することができます。
          </p>
        </div>
        <div class="level-right">
          <p class="level-item">
            <b-button
              type="is-primary"
              icon-right="repeat"
              outlined
              @click="willCalcurate"
              :loading="isLoading"
              >計算する</b-button
            >
          </p>
        </div>
      </div>
    </b-message>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <CardComponent
          class="tile is-child has-table"
          title="エリアで検索する"
          :has-button-slot="true"
          hasFooterSlot
        >
          <refresh-button
            slot="button"
            label="更新"
            :isLoading="isLoading"
            @button-click="willReFetchAreaSummary"
          />
          <b-table
            :loading="isLoading"
            :data="tPaymentSoftAreas"
            hoverable
            @select="(row) => (areaSearch.area = row.area)"
          >
            <b-table-column label="" width="4em" v-slot="{ row }">
              <b-radio
                name="search_area"
                v-model="areaSearch.area"
                :native-value="row.area"
              ></b-radio>
            </b-table-column>
            <b-table-column label="" v-slot="{ row }">
              <span>{{ row.area.label }}</span>
            </b-table-column>
            <b-table-column label="実績" numeric v-slot="{ row }">
              <span v-if="row.totalCount">{{
                row.totalCount.toLocaleString()
              }}</span>
              <span v-else />
            </b-table-column>
            <b-table-column label="未承認" numeric v-slot="{ row }">
              <span v-if="row.confirmingCount">{{
                row.confirmingCount.toLocaleString()
              }}</span>
              <span v-else />
            </b-table-column>
            <b-table-column label="承認済み" numeric v-slot="{ row }">
              <span v-if="row.confirmedCount">{{
                row.confirmedCount.toLocaleString()
              }}</span>
              <span v-else />
            </b-table-column>
            <b-table-column label="計算済み" numeric v-slot="{ row }">
              <span v-if="row.stagingCount">{{
                row.stagingCount.toLocaleString()
              }}</span>
              <span v-else />
            </b-table-column>
            <section class="section" slot="empty">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
          <div class="py-3" slot="footer">
            <b-field label="" horizontal>
              <b-button
                :type="areaSearch.area ? 'is-primary' : ''"
                outlined
                :disabled="!areaSearch.area"
                @click="fetchByArea"
                >検索</b-button
              >
            </b-field>
          </div>
        </CardComponent>
      </div>
      <div class="tile is-parent">
        <CardComponent
          class="tile is-child"
          title="スタッフ・プログラムで検索する"
          hasFooterSlot
        >
          <b-loading :active="isLoading" :is-full-page="false" />
          <b-field label="インストラクター">
            <PaymentInstrcutorSearch v-model="scopeSearch.mInstructor" />
          </b-field>
          <ProgramSearchField
            v-model="scopeSearch.mProgram"
            :area="null"
            class="mb-3"
          />
          <div class="py-3" slot="footer">
            <b-field label="" horizontal>
              <b-button
                :type="scopeSearch.hasScopeCondition ? 'is-primary' : ''"
                outlined
                :disabled="!scopeSearch.hasScopeCondition"
                @click="fetchByScope"
                >検索</b-button
              >
            </b-field>
          </div>
        </CardComponent>
      </div>
    </div>
    <CardComponent
      v-if="resultCondition && resultCondition.type == 'area'"
      :title="`${paymentYm.rangeLabel} 実績一覧 ／ エリアで検索 ／ ${resultCondition.area.label}`"
      icon="flag"
      class="tile is-child has-table"
      style="min-height: 800px"
    >
      <CardToolbar slot="toolbar">
        <div class="block" slot="left">
          <b-field label="施設" class="has-check">
            <EnumCheckboxPicker
              :members="resultCondition.FACILITY_OPTIONS"
              v-model="resultCondition.facilities"
            />
          </b-field>
        </div>
      </CardToolbar>
      <SoftCheckinProjectionTable
        :tSoftCheckinProjectionStructs="filteredProjectionStructs"
        :loading="isLoading"
        @will-remove="willRemoveProjectionStruct"
      />
    </CardComponent>
    <SoftCheckinScopeCard
      v-else-if="resultCondition && resultCondition.type == 'scope'"
      :title="`${paymentYm.rangeLabel} 実績一覧 ／ スタッフ・プログラムで検索`"
      :searchCondition="resultCondition"
      :tSoftCheckins="scopeTSoftCheckins"
      :loading="isLoading"
      @didEdit="didEditScopeRow"
      @didDelete="didDeleteScopeRow"
    />
  </section>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { SoftCheckinSearch, TSoftCheckinProjectionStruct } from '@/types/typePayment' // eslint-disable-line no-unused-vars
import PaymentInstrcutorSearch from '@/components/Payment/PaymentInstrcutorSearch.vue'
import ProgramSearchField from '@/components/Payment/ProgramSearchField.vue'
import { mapActions, mapGetters } from 'vuex'
import SoftCheckinProjectionTable from '@/components/Payment/SoftCheckinProjectionTable.vue'
import { ApiPaymentSoft } from '@/api'
import SoftCheckinScopeCard from '@/components/Payment/SoftCheckinScopeCard.vue'
import { Collection, parsePaymentYm } from '@/util'
import RefreshButton from '@/components/RefreshButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { TPaymentWork } from '@/types/typeWork'
import EnumCheckboxPicker from '@/components/EnumCheckboxPicker.vue'

export default {
  mixins: [BaseMixin],
  components: { CardComponent, PaymentInstrcutorSearch, ProgramSearchField, SoftCheckinProjectionTable, SoftCheckinScopeCard, RefreshButton, CardToolbar, EnumCheckboxPicker },
  props: {
    pathPaymentYm: String,
    tPaymentWork: TPaymentWork
  },
  data () {
    return {
      tPaymentSoftAreas: [],
      areaSearch: new SoftCheckinSearch(this.pathPaymentYm, 'area'),
      tSoftCheckinProjectionStructs: [],
      scopeSearch: new SoftCheckinSearch(this.pathPaymentYm, 'scope'),
      scopeTSoftCheckins: [],
      resultCondition: null,
    }
  },
  computed: {
    ...mapGetters('storeAuth', ['hasCalcPermission']),
    paymentYm () {
      return parsePaymentYm(this.pathPaymentYm)
    },
    filteredProjectionStructs () {
      if (!this.resultCondition) {
        return this.tSoftCheckinProjectionStructs
      }
      return this.tSoftCheckinProjectionStructs.filter(struct => this.resultCondition.facilities.includes(struct.facility))
    }
  },
  methods: {
    ...mapActions('storePayment', ['createPaymentWork']),
    willReFetchAreaSummary () {
      this.isLoading = true
      setTimeout(() => {
        ApiPaymentSoft.fetchSoftAreas(this.pathPaymentYm).then(({ tPaymentSoftAreas }) => {
          this.tPaymentSoftAreas = tPaymentSoftAreas
          this.isLoading = false
        }).catch(this.handleError)
      }, 300)
    },
    fetchByScope () {
      this.resultCondition = this.scopeSearch.copy()
      this.isLoading = true
      ApiPaymentSoft.fetchSoftCheckins(this.scopeSearch).then(({ tSoftCheckins }) => {
        this.scopeTSoftCheckins = tSoftCheckins
        this.isLoading = false
      }).catch(this.handleError)
    },
    didEditScopeRow (tSoftCheckin) {
      this.scopeTSoftCheckins = new Collection(this.scopeTSoftCheckins).set(tSoftCheckin).members
    },
    didDeleteScopeRow (checkinId) {
      this.scopeTSoftCheckins = new Collection(this.scopeTSoftCheckins).remove(checkinId).members
    },
    fetchByArea () {
      this.resultCondition = this.areaSearch.copy()
      this.resultCondition.setFacilityAll()
      this.isLoading = true
      this.tSoftCheckinProjectionStructs = []
      ApiPaymentSoft.fetchSoftCheckins(this.areaSearch).then(({ tSoftCheckins }) => {
        this.tSoftCheckinProjectionStructs = TSoftCheckinProjectionStruct.parse(tSoftCheckins)
        this.isLoading = false
      }).catch(this.handleError)
    },
    willCalcurate () {
      console.log('willCalcurate')
      this.isLoading = true
      setTimeout(() => {
        this.createPaymentWork(this.pathPaymentYm).then(() => {
          this.willReFetchAreaSummary()
          this.resultCondition = null
          this.alertSuccess('計算結果を作成しました')
        }).catch(this.handleError)
      }, 300)
    },
    willToWork () {
      this.$router.push({
        name: 'PaymentWork'
      })
    },
    willRemoveProjectionStruct (projectionStruct) {
      console.log(projectionStruct, 'willRemoveProjectionStruct')
      this.tSoftCheckinProjectionStructs = this.tSoftCheckinProjectionStructs.filter(struct => struct !== projectionStruct)
    }
  },
  created () {
    this.isLoading = true
  },
  mounted () {
    this.willReFetchAreaSummary()
  },
}
</script>
