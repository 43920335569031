<template>
  <b-table
    :loading="loading"
    striped
    default-sort="lessonStartAtSortVal"
    :data="tSoftCheckins"
    style="font-size: 0.9rem"
  >
    <b-table-column
      label="実施日"
      width="14em"
      sortable
      field="lessonStartAtSortVal"
      v-slot="{ row }"
    >
      <span>{{ row.lessonStartAt.format('YYYY/MM/DD(dd) HH:mm') }}</span>
      <HolidayIcon :area="row.area" :date="row.lessonStartAt" blankIfNot />
    </b-table-column>
    <b-table-column label="施設" width="14em" v-slot="{ row }">
      <span v-if="row.facility">{{ row.facility.label }}</span>
      <span v-else>-</span>
    </b-table-column>
    <b-table-column label="講師" width="8em" v-slot="{ row }">
      <InstructorLabel
        v-if="row.mInstructor"
        :mInstructor="row.mInstructor"
        position="is-right"
      />
      <span v-else-if="row.instructorId">id:({{ row.instructorId }})</span>
      <span v-else>-</span>
    </b-table-column>
    <b-table-column label="プログラム" v-slot="{ row }">
      <PaymentProgramLabel :programId="row.programId" position="is-right" />
    </b-table-column>
    <b-table-column label="時間" width="4em" numeric v-slot="{ row }">
      <span>{{ row.minutes }}分</span>
    </b-table-column>
    <b-table-column width="2em" centered v-slot="{ row }">
      <b-tooltip
        v-if="row.note"
        :label="row.note"
        type="is-dark"
      >
        <b-icon icon="comment-outline" />
      </b-tooltip>
      <span v-else />
    </b-table-column>
    <b-table-column label="カテゴリ" width="6em" v-slot="{ row }">
      <span v-if="row.category">{{ row.category.label }}</span>
      <span v-else>特例</span>
    </b-table-column>
    <b-table-column label="単価" width="5em" numeric v-slot="{ row }">
      <span v-if="row.hasFixedAmount">-</span>
      <span v-else-if="row.hasUnitAmount">{{
        row.unitAmount.toLocaleString()
      }}</span>
      <small v-else class="has-text-danger">未設定</small>
    </b-table-column>
    <b-table-column label="各種手当" width="6em" numeric v-slot="{ row }">
      <span v-if="row.hasFixedAmount">-</span>
      <b-tooltip
        v-else-if="row.instillAmount"
        :label="row.instillToolTip"
        type="is-dark"
      >
        <span>{{ row.instillAmount.toLocaleString() }}</span>
      </b-tooltip>
      <span v-else />
    </b-table-column>
    <b-table-column label="固定" width="5em" numeric v-slot="{ row }">
      <span v-if="row.hasFixedAmount">{{
        row.fixedAmount.toLocaleString()
      }}</span>
      <span v-else />
    </b-table-column>
    <b-table-column label="承認" width="4em" v-slot="{ row }">
      <b-tooltip label="支払い対象とする" type="is-dark">
        <b-checkbox
          class="ml-1"
          type="is-success"
          v-model="row.confirmFlagChange"
          @input="willChangeStatus(row)"
        />
      </b-tooltip>
    </b-table-column>
    <b-table-column label="" width="3em" v-slot="{ row }">
      <b-button
        type="is-warning"
        size="is-small"
        icon-left="square-edit-outline"
        @click="willEdit(row)"
      />
    </b-table-column>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="loading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import { ApiCheckin } from '@/api'
import { TSoftCheckin } from '@/types/typePayment' // eslint-disable-line no-unused-vars
import SoftCheckinEditFormVue from './SoftCheckinEditForm.vue'
import HolidayIcon from '../Shared/HolidayIcon.vue'
import PaymentProgramLabel from './PaymentProgramLabel.vue'

export default {
  components: { HolidayIcon, PaymentProgramLabel },
  props: {
    loading: Boolean,
    total: Number,
    tSoftCheckins: { // TSoftCheckin[]
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /** @param {TSoftCheckin} tSoftCheckin */
    willChangeStatus (tSoftCheckin) {
      console.log(tSoftCheckin, 'willChangeStatus')
      const bool = tSoftCheckin.confirmFlagChange
      ApiCheckin.toggleConfirm([tSoftCheckin.id], bool).then(() => {
        tSoftCheckin.setConfirmed(bool)
        this.$emit('change-confirm', bool)
      })
    },
    /** @param {TSoftCheckin} tSoftCheckin */
    willEdit (tSoftCheckin) {
      console.log(tSoftCheckin, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: SoftCheckinEditFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { tSoftCheckin },
        events: {
          edit: (tSoftCheckin) => {
            this.$emit('didEdit', tSoftCheckin)
          },
          deleted: (checkinId) => {
            this.$emit('didDelete', checkinId)
          }
        }
      })
    }
  }
}
</script>
