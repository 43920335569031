<template>
  <tr :key="row.id">
    <td></td>
    <td>
      <span>{{ row.lessonStartAt.format('YYYY/MM/DD(dd) HH:mm') }}</span>
    </td>
    <td>
      <span v-if="row.facility">{{ row.facility.options.shortLabel }}</span>
      <span v-else>-</span>
    </td>
    <td>
      <InstructorLabel
        v-if="mInstructor"
        :mInstructor="mInstructor"
        position="is-right"
      />
      <span v-else-if="row.instructorId">id:({{ row.instructorId }})</span>
      <span v-else>-</span>
    </td>
    <td>
      <PaymentProgramLabel :programId="row.programId" position="is-right" />
    </td>
    <td class="has-text-right">
      <span>{{ row.minutes }}分</span>
    </td>
    <td class="has-text-centered">
      <b-tooltip
        v-if="row.note"
        :label="row.note"
        type="is-dark"
      >
        <b-icon icon="comment-outline" />
      </b-tooltip>
      <span v-else />
    </td>
    <td>
      <span v-if="row.category">{{ row.category.label }}</span>
      <span v-else>特例</span>
    </td>
    <td class="has-text-right">
      <span v-if="row.hasFixedAmount">-</span>
      <span v-else-if="row.hasUnitAmount">{{
        row.unitAmount.toLocaleString()
      }}</span>
      <small v-else class="has-text-danger">未設定</small>
    </td>
    <td class="has-text-right">
      <span v-if="row.hasFixedAmount">-</span>
      <b-tooltip
        v-else-if="row.instillAmount"
        :label="row.instillToolTip"
        type="is-dark"
      >
        <span>{{ row.instillAmount.toLocaleString() }}</span>
      </b-tooltip>
      <span v-else />
    </td>
    <td class="has-text-right">
      <span v-if="row.hasFixedAmount">{{
        row.fixedAmount.toLocaleString()
      }}</span>
      <span v-else />
    </td>
    <td class="has-text-centered">
      <b-tooltip label="支払い対象とする" type="is-dark">
        <b-checkbox
          class="ml-1"
          type="is-success"
          v-model="row.confirmFlagChange"
          @input="willChangeStatus(row)"
        />
      </b-tooltip>
    </td>
    <td>
      <b-button
        type="is-warning"
        size="is-small"
        icon-left="square-edit-outline"
        @click="willEdit(row)"
      />
    </td>
  </tr>
</template>

<script>
import { ApiCheckin } from '@/api'
import { TSoftCheckin } from '@/types/typePayment' // eslint-disable-line no-unused-vars
import { mapGetters } from 'vuex'
import SoftCheckinEditFormVue from './SoftCheckinEditForm.vue'
import PaymentProgramLabel from './PaymentProgramLabel.vue'

export default {
  components: { PaymentProgramLabel },
  props: {
    row: TSoftCheckin
  },
  computed: {
    ...mapGetters('storePayment', ['findMInstructor']),
    mInstructor () {
      return this.findMInstructor(this.row.instructorId)
    },
  },
  methods: {
    /** @param {TSoftCheckin} tSoftCheckin */
    willChangeStatus (tSoftCheckin) {
      console.log(tSoftCheckin, 'willChangeStatus')
      const bool = tSoftCheckin.confirmFlagChange
      ApiCheckin.toggleConfirm([tSoftCheckin.id], bool).then(() => {
        tSoftCheckin.setConfirmed(bool)
        this.$emit('change-confirm', bool)
      })
    },
    /** @param {TSoftCheckin} tSoftCheckin */
    willEdit (tSoftCheckin) {
      console.log(tSoftCheckin, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: SoftCheckinEditFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { tSoftCheckin },
        events: {
          edit: (tSoftCheckin) => {
            this.$emit('didEdit', tSoftCheckin)
          },
          deleted: (checkinId) => {
            this.$emit('didDelete', checkinId)
          }
        }
      })
    }
  }
}
</script>
