<template>
  <CardComponent
    :title="cardTitle"
    icon="flag"
    class="tile is-child has-table"
    style="min-height: 800px"
  >
    <CardToolbar>
      <div slot="left">
        <b-field label="エリア" class="has-check">
          <EnumPicker
            noneLabel="全て"
            :members="AREA_OPTIONS"
            :grouped="true"
            :grouped-multiline="false"
            v-model="searchCondition.area"
          />
        </b-field>
      </div>
      <div slot="right">
        <span v-if="allComfirmed">
          <b-icon icon="check" />全て承認済みです
        </span>
        <b-button
          v-else
          type="is-danger"
          size="is-small"
          icon-left="check"
          outlined
          @click="willConfirmScope"
          >一括</b-button
        >
      </div>
    </CardToolbar>
    <SoftCheckinScopeTable
      :tSoftCheckins="filterTSoftCheckins"
      :loading="loading"
      @didEdit="(tSoftCheckin) => $emit('didEdit', tSoftCheckin)"
      @didDelete="(checkinId) => $emit('didDelete', checkinId)"
    />
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { ApiCheckin } from '@/api'
import CardToolbar from '@/components/CardToolbar.vue'
import SoftCheckinScopeTable from '@/components/Payment/SoftCheckinScopeTable.vue'
import EnumPicker from '../EnumPicker.vue'
import { Enums } from '@/types/enum'
import { SoftCheckinSearch } from '@/types/typePayment'

export default {
  mixins: [BaseMixin],
  components: { CardComponent, CardToolbar, SoftCheckinScopeTable, EnumPicker },
  props: {
    loading: Boolean,
    title: String,
    tSoftCheckins: Array,
    searchCondition: SoftCheckinSearch,
  },
  computed: {
    AREA_OPTIONS: () => Enums.Area.list,
    allComfirmed () {
      return this.tSoftCheckins.every(row => row.confirmFlag)
    },
    filterTSoftCheckins () {
      if (!this.searchCondition.area) {
        return this.tSoftCheckins
      }
      return this.tSoftCheckins.filter(row => row.area.value === this.searchCondition.area.value)
    },
    cardTitle () {
      let ret = this.title
      if (this.searchCondition.mInstructor) {
        ret = `${ret} ／ ${this.searchCondition.mInstructor.name}`
      }
      if (this.searchCondition.mProgram) {
        ret = `${ret} ／ ${this.searchCondition.mProgram.name}`
      }
      return ret
    }
  },
  methods: {
    willConfirmScope () {
      console.log('willConfirmScope')
      this.$buefy.dialog.confirm({
        message: '全て承認済みにしますか？',
        onConfirm: () => {
          const checkinIds = this.tSoftCheckins.map(row => row.id)
          ApiCheckin.toggleConfirm(checkinIds, true).then(() => {
            this.tSoftCheckins.forEach(row => row.setConfirmed(true))
          }).catch(this.handleError)
        }
      })
    }
  },
}
</script>
