<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <CardComponent
      :isScrollable="true"
      :title="cardTitle"
      icon="ballot"
      style="width: 800px"
      headerIcon="close"
      hasBottomSlot
      @header-icon-click="$emit('close')"
    >
      <form>
        <b-field label="レッスン" horizontal>
          <PaymentProgramLabel
            :programId="tSoftCheckin.programId"
            position="is-bottom"
          />
        </b-field>
        <b-field label="日程" horizontal>
          <span>{{
            tSoftCheckin.lessonStartAt.format('YYYY/MM/DD(dd) HH:mm ~')
          }}</span>
        </b-field>
        <b-field label="実施報告" horizontal>
          <span>{{
            tSoftCheckin.checkinAt.format('YYYY/MM/DD(dd) HH:mm')
          }}</span>
        </b-field>
        <b-field label="Feeカテゴリ" horizontal>
          <span v-if="tSoftCheckin.category">{{
            tSoftCheckin.category.label
          }}</span>
          <span v-else>特例</span>
        </b-field>
        <b-field
          label="単価/時"
          horizontal
          :type="tSoftCheckin.hasUnitAmount ? null : 'is-danger'"
          :message="
            tSoftCheckin.hasUnitAmount
              ? ''
              : 'インストラクター管理画面からフィーを設定してください'
          "
        >
          <span v-if="tSoftCheckin.hasUnitAmount"
            >{{ tSoftCheckin.unitAmount.toLocaleString() }}円</span
          >
          <span v-else class="has-text-danger">未設定</span>
        </b-field>
        <b-field
          label="実績時間"
          horizontal
          message="Jrカテゴリでは１分以上＝１本扱いで計算されます"
        >
          <MinutesField
            v-model="softCheckinEditForm.minutes"
            rules="required"
            :presets="[0, 10]"
          />
        </b-field>
        <b-field label="各種手当" horizontal>
          <b-field grouped group-multiline>
            <div
              v-for="instill in INSTILL_OPTIONS"
              class="control"
              :key="instill.value"
            >
              <b-checkbox
                v-model="softCheckinEditForm.feeInstills"
                :native-value="instill"
              >
                {{ instill.label }}
              </b-checkbox>
            </div>
          </b-field>
        </b-field>
        <b-field
          label="固定金額"
          horizontal
          message="単価*実績時間＋手当に収まらない場合に入力してください"
        >
          <ValidationField rules="numeric">
            <b-input
              type="text"
              inputmode="numeric"
              pattern="\d*"
              icon="currency-cny"
              icon-right="close-circle"
              icon-right-clickable
              @icon-right-click="softCheckinEditForm.fixedAmount = ''"
              v-model="softCheckinEditForm.fixedAmount"
            >
            </b-input>
          </ValidationField>
        </b-field>
        <b-field label="備考" horizontal>
          <b-input
            type="textarea"
            v-model="softCheckinEditForm.note"
            maxlength="500"
            :rows="2"
          />
        </b-field>
      </form>
      <div slot="bottom" class="level p-4">
        <div class="level-left">
          <div class="level-item buttons">
            <b-button
              native-type="button"
              type="is-primary"
              :loading="isLoading"
              @click.prevent="handleSubmit(willSave)"
              >登録</b-button
            >
            <b-button
              type="is-light"
              @click="$emit('close')"
              :loading="isLoading"
              >閉じる</b-button
            >
          </div>
        </div>
        <div class="level-right">
          <div class="level-item buttons">
            <b-button
              type="is-danger"
              size="is-small"
              icon-left="backspace-outline"
              outlined
              @click="willRevert"
              >実施確認を取り消す</b-button
            >
          </div>
        </div>
      </div>
    </CardComponent>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { mapGetters } from 'vuex'
import { SoftCheckinEditForm, TSoftCheckin } from '@/types/typePayment'
import MinutesField from '../Shared/MinutesField.vue'
import { Enums } from '@/types/enum'
import { ApiPaymentSoft } from '@/api'
import PaymentProgramLabel from './PaymentProgramLabel.vue'
export default {
  mixins: [BaseMixin],
  props: {
    tSoftCheckin: TSoftCheckin,
  },
  components: { CardComponent, MinutesField, PaymentProgramLabel },
  computed: {
    ...mapGetters('storePayment', ['findMProgram', 'findMInstructor']),
    INSTILL_OPTIONS: () => Enums.FeeInstill.list,
    mProgram () {
      return this.findMProgram(this.tSoftCheckin.programId)
    },
    mInstructor () {
      return this.findMInstructor(this.tSoftCheckin.instructorId)
    },
    cardTitle () {
      const programName = this.mProgram ? this.mProgram.name : '-'
      const facilityLabel = this.tSoftCheckin && this.tSoftCheckin.facility ? this.tSoftCheckin.facility.label : '-'
      const instructorLabel = this.mInstructor ? `#${this.mInstructor.code} ${this.mInstructor.name}` : '-'
      return `実績管理  ${instructorLabel} ${programName} @${facilityLabel} ID:${this.tSoftCheckin.id}`
    },
  },
  data () {
    return {
      softCheckinEditForm: new SoftCheckinEditForm(this.tSoftCheckin)
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      ApiPaymentSoft.editSoftCheckin(this.softCheckinEditForm).then(({ tSoftCheckin }) => {
        this.$emit('edit', tSoftCheckin)
        this.toastInfo('実績を編集しました。')
        this.$emit('close')
        this.isLoading = false
      }).catch(this.handleError)
    },
    willRevert () {
      console.log('willRevert')
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: '実行',
        cancelText: 'キャンセル',
        message: '実施確認を取り消し、スケジュールを未実施状態に戻します',
        onConfirm: () => {
          this.isLoading = true
          ApiPaymentSoft.deleteSoftCheckin(this.tSoftCheckin.id).then(() => {
            this.$emit('deleted', this.tSoftCheckin.id)
            this.toastInfo('実施確認を取り消しました。')
            this.$emit('close')
            this.isLoading = false
          }).catch(this.handleError)
        }
      })
    },
  },
  mounted () {
  }
}
</script>
